import { memo, SVGProps } from 'react';

const Frame48096383Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 49 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect x={0.27272} width={48} height={48} rx={24} fill='white' />
    <path
      d='M25.3843 35.1111H20.9398C20.6451 35.1111 20.3625 34.994 20.1541 34.7856C19.9458 34.5772 19.8287 34.2946 19.8287 33.9999V27.1111H17.6065C17.3118 27.1111 17.0292 26.994 16.8208 26.7856C16.6124 26.5772 16.4954 26.2946 16.4954 25.9999V21.9999C16.4954 21.7053 16.6124 21.4226 16.8208 21.2143C17.0292 21.0059 17.3118 20.8888 17.6065 20.8888H19.8287V18.9999C19.9066 17.307 20.6516 15.7138 21.9009 14.5686C23.1502 13.4234 24.8021 12.8195 26.4954 12.8888H29.8287C30.1234 12.8888 30.406 13.0059 30.6144 13.2143C30.8228 13.4226 30.9398 13.7053 30.9398 13.9999V17.9999C30.9398 18.2946 30.8228 18.5772 30.6144 18.7856C30.406 18.994 30.1234 19.1111 29.8287 19.1111H26.4954V20.8888H29.8287C30.0003 20.8875 30.1698 20.926 30.324 21.0012C30.4782 21.0763 30.6129 21.1862 30.7176 21.3222C30.8211 21.4602 30.891 21.6204 30.9219 21.7901C30.9528 21.9598 30.9437 22.1344 30.8954 22.2999L29.7843 26.2999C29.7171 26.5403 29.5709 26.751 29.3693 26.8981C29.1678 27.0453 28.9225 27.1203 28.6732 27.1111H26.4954V33.9999C26.4954 34.2946 26.3783 34.5772 26.1699 34.7856C25.9616 34.994 25.6789 35.1111 25.3843 35.1111V35.1111ZM22.0509 32.8888H24.2732V25.9999C24.2732 25.7053 24.3902 25.4226 24.5986 25.2143C24.807 25.0059 25.0896 24.8888 25.3843 24.8888H27.8732L28.362 23.1111H25.3843C25.0896 23.1111 24.807 22.994 24.5986 22.7856C24.3902 22.5772 24.2732 22.2946 24.2732 21.9999V18.9999C24.3017 18.43 24.5483 17.893 24.962 17.4999C25.3757 17.1069 25.9248 16.8881 26.4954 16.8888H28.7176V15.1111H26.4954C25.3918 15.0438 24.3063 15.4144 23.4742 16.1425C22.6422 16.8705 22.1308 17.8972 22.0509 18.9999V21.9999C22.0509 22.2946 21.9339 22.5772 21.7255 22.7856C21.5171 22.994 21.2345 23.1111 20.9398 23.1111H18.7176V24.8888H20.9398C21.2345 24.8888 21.5171 25.0059 21.7255 25.2143C21.9339 25.4226 22.0509 25.7053 22.0509 25.9999V32.8888Z'
      fill='#85CBD9'
    />
  </svg>
);
const Memo = memo(Frame48096383Icon);
export { Memo as Frame48096383Icon };
